import { Colors } from '../../config/variables';
import { GameMode } from '../../consts';
import { setBrokenGame, setCurrentBonus, setIsBuyFeaturePopupOpened, setSlotMachineInitialized } from '../../gql/cache';
import { calcPercentage, canPressSpin, getGameModeByBonusId } from '../../utils';
import { UiButton } from '../components/ui/uiButton';
import { EventTypes, eventManager } from '../config';

export class SpinBtn extends UiButton {
  private gameMode: GameMode;

  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  private isSlotStopped = false;

  private isFreeSpinsWin = false;

  private isOpenedMessageBanner = false;

  private isInTransition = false;

  private isOpeningScenePlaying = false;

  private isSpinShortCut = false;

  private isRespinRevivalWaiting = false;

  private isPopupFreeRoundsOpened = false;

  constructor() {
    super('spin');

    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;

    this.interactive = !this.isDisabled;
    this.btn.tint = Colors.GAME_COLOR;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleUpdateIntent();
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOTS_STOPPED, (isSlotStopped: boolean) => {
      this.isSlotStopped = isSlotStopped;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_OPENED_MESSAGE_BANNER, (isOpenedMessageBanner: boolean) => {
      this.isOpenedMessageBanner = isOpenedMessageBanner;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_IN_TRANSITION, (isInTransition: boolean) => {
      this.isInTransition = isInTransition;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_SHORTCUT_ON, (isSpinShortCut: boolean) => {
      this.isSpinShortCut = isSpinShortCut;
      this.handleDisable();
    });

    eventManager.on(EventTypes.SET_IS_RESPIN_REVIVAL_WAITING, (isRespinRevivalWaiting: boolean) => {
      this.isRespinRevivalWaiting = isRespinRevivalWaiting;
      this.handleDisable();
    });

    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_OPENING_SCENE_PLAYING, (isPlaying: boolean) => {
      this.isOpeningScenePlaying = isPlaying;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });
  };

  private handleUpdateIntent = (): void => {
    if (this.isAutoSpins) {
      this.handleDisable();
    } else {
      this.updateIntent(this.isSlotBusy ? 'skip' : 'spin');
    }
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setSlotMachineInitialized()) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      eventManager.emit(EventTypes.TOGGLE_SPIN);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      !canPressSpin({
        gameMode: this.gameMode,
        isFreeSpinsWin: this.isFreeSpinsWin,
        isSlotBusy: this.isSlotBusy,
        isSlotStopped: this.isSlotStopped,
        isSpinInProgress: this.isSpinInProgress,
        isOpenedMessageBanner: this.isOpenedMessageBanner,
        isInTransition: this.isInTransition,
        isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
        isOpeningScenePlaying: this.isOpeningScenePlaying,
        isSpinShortCut: this.isSpinShortCut,
        isRespinRevivalWaiting: this.isRespinRevivalWaiting,
        isAutoPlay: this.isAutoSpins,
      }) || this.isPopupFreeRoundsOpened,
    );
  };

  public override handlePosition(): void {
    this.btn.anchor.set(1, 0.5);
    let size = calcPercentage(this.applicationSize.width, 17);
    const gap = calcPercentage(this.applicationSize.width, 0.95);
    let x = this.applicationSize.width - gap;
    let y = this.applicationSize.height / 2;
    this.setSize(size);

    if (this.isPortraitMode) {
      size = calcPercentage(this.applicationSize.height, 17);
      this.btn.anchor.set(0.5, 1);
      x = this.applicationSize.width / 2;
      y = this.applicationSize.height - gap;
      this.setSize(size);
    }

    this.x = x;
    this.y = y;
  }
}
