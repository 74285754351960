import { setIsOpeningScenePlaying, setReplayBet } from '../../gql/cache';
import { EventTypes, eventManager } from '../../slotMachine/config';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { TickerSpine } from '../components/spine';

class OpeningScreen extends ViewContainer {
  private spine: TickerSpine<'openning'>;

  private skipFlg = false;

  public constructor() {
    super();
    this.spine = new TickerSpine('openning');

    this.addChild(this.spine);

    this.spine.interactive = true;
    this.spine.on('mousedown', () => {
      this.skipAnimations();
    });
    this.spine.on('touchstart', () => {
      this.skipAnimations();
    });

    if (setReplayBet()) {
      setTimeout(() => {
        this.BrokenGameStart();
      });
    }

    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  public startAnimation() {
    setIsOpeningScenePlaying(true);

    this.spine.state.setAnimation(0, 'start');
    const delay = Tween.createDelayAnimation(this.spine.spineData.findAnimation('start').duration * 1000);
    delay.addOnComplete(() => {
      this.handleDestroy();
    });
    delay.start();
  }

  public BrokenGameStart() {
    this.visible = false;
    this.handleDestroy();
  }

  private handleDestroy() {
    this.visible = false;
    setIsOpeningScenePlaying(false);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
    this.destroy({ children: true });
  }

  private skipAnimations() {
    if (!this.skipFlg) {
      this.skipFlg = true;
      /*      eventManager.emit(EventTypes.START_FADE, 500, 500);
      const delay = Tween.createDelayAnimation(500);
      delay.addOnComplete(() => {
        this.spine.state.setEmptyAnimation(0);
        this.handleDestroy();
      });
      delay.start();
      */
    }
  }

  public resize = (width: number, height: number): void => {
    this.x = width / 2;
    this.y = height / 2;

    const size = { width: 2000, height: 2000 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    const scale = bgAspectRatio > aspectRatio ? height / size.height : width / size.width;
    this.scale.set(scale);
  };

  protected bindedResize = this.resize.bind(this);
}

export default OpeningScreen;
