import { useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IAuthInput } from '../../global.d';
import {
  setCurrentBonus,
  setIsAuthorized,
  setIsTimeoutErrorMessage,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setRespinCnt,
  setStressful,
} from '../../gql/cache';
import { EventTypes, SlotMachineState, eventManager } from '../../slotMachine/config';
import { parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const isAuthorized = useReactiveVar(setIsAuthorized);
  const progress = useReactiveVar(setProgress);
  const { i18n } = useTranslation();

  const handleIdle = useCallback(
    (state: SlotMachineState) => {
      if (state !== SlotMachineState.IDLE) return;
      const replayFreeSpins = setReplayFreeSpinBets();
      if (!replayFreeSpins.length || setRespinCnt() === replayFreeSpins.length) {
        setIsTimeoutErrorMessage(true);
        setStressful({
          show: true,
          type: 'none',
          message: i18n.t('replayBetMessage'),
        });
      }
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.POST_RENDER, () => {
      setTimeout(() => {
        if (setReplayBet()) {
          eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
          const replayFreeSpins = setReplayFreeSpinBets();
          if (replayFreeSpins.length) {
            const triggerSpin = replayFreeSpins.shift();
            setReplayBet(triggerSpin);
          }
          eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
          if (setCurrentBonus().isActive && setCurrentBonus().betId) {
            eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, () => {
              eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
                handleIdle(state);
              });
            });
          } else {
            eventManager.emit(EventTypes.TOGGLE_SPIN);
            eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
              handleIdle(state);
            });
          }
        }
      });
    });
  }, [i18n, handleIdle]);

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  return (
    <>
      <LoadScreen />
      {isAuthorized && progress.status >= 100 && <GameScreen />}
    </>
  );
};

export default App;
