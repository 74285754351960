import { getFragmentData } from '../../gql';
import { setBrokenGame, setReplayBet, setRespinCnt, setSlotConfig, setUserLastBetResult } from '../../gql/cache';
import client from '../../gql/client';
import { historyNodeFragment } from '../../gql/fragment';
import { replaySlotHistoryGql, slotHistoryGql } from '../../gql/query';
import { REELS_AMOUNT } from '../../slotMachine/config';
import { queryParams } from '../../utils';

export const useLastBet = () => {
  const getLastBet = async () => {
    const query = queryParams.has('replayBetId') ? replaySlotHistoryGql : slotHistoryGql;
    const betsData = await client.query({
      query,
      variables: {
        input: { last: 1, filter: { slotId: setSlotConfig().id } },
      },
      fetchPolicy: 'network-only',
    });
    if (betsData.data.bets?.edges[0]) {
      const node = getFragmentData(historyNodeFragment, betsData.data.bets.edges[0].node);
      setUserLastBetResult(node);
    }
  };

  const getLastBetFromBonusBetId = async () => {
    const lastBetData = setUserLastBetResult();
    if (lastBetData === undefined || lastBetData === null) return;
    if (lastBetData?.result.reelPositions.length > 1) return;

    const reelPositions = lastBetData?.data.features.gameRoundStore.baseReelPositions!.slice(0, REELS_AMOUNT);
    reelPositions[2] = lastBetData?.result.reelPositions[0]!;
    //TO DO
    setUserLastBetResult({
      ...lastBetData!,
      result: {
        ...lastBetData!.result,
        reelPositions: reelPositions!,
      },
    });

    if (setBrokenGame()) {
      setRespinCnt(lastBetData.data.features.gameRoundStore.respins);
      if (setReplayBet() && lastBetData.data.features.gameRoundStore.respins - 1 > 0) {
        setRespinCnt(lastBetData.data.features.gameRoundStore.respins - 1);
      }
    }

    /*
    const bet = await client.query({
      query: slotBetGql,
      variables: { input: { id: lastBetData!.userBonus!.betId } },
      fetchPolicy: 'network-only',
    });
    if (bet.data.bet?.result) {
      //if mode changed from freegame to basegame
      if (
        !setBrokenGame() &&
        (bet.data.bet.reelSetId === reelSets[GameMode.FREE_SPINS]![0] ||
          bet.data.bet.reelSetId === reelSets[GameMode.BUY_FEATURE]![0])
      ) {
        setUserLastBetResult({
          ...lastBetData!,
          reelSetId: reelSets[GameMode.REGULAR]![0]!,
          result: {
            ...lastBetData!.result,
            reelPositions: FREEGAME_TO_BASEGAME_REEL_POSITIONS,
          },
        });
      } else {
        setUserLastBetResult({
          ...lastBetData!,
          result: bet.data.bet.result,
          reelSetId: bet.data.bet.reelSetId,
        });
      }
    } else {
      setUserLastBetResult({
        ...lastBetData!,
        reelSetId: reelSets[GameMode.REGULAR]![0]!,
        result: {
          ...lastBetData!.result,
          reelPositions: [0, 0, 0, 0, 0],
        },
      });
    }
    */
  };
  return {
    getLastBet,
    getLastBetFromBonusBetId,
  };
};
