import { gql } from './__generated__';

export const userBonusFragment = gql(`
  fragment userBonus on UserBonus {
    id
    coinValue
    coinAmount
    data
    betId
    totalWinAmount
    rounds
    roundsPlayed
    bonus {
      id
      reelSetId
      type
      coinAmount
    }
    bonusId
    status
    isFreeBet
  }
`);

export const historyNodeFragment = gql(`
  fragment historyNode on Bet {
    id
    coinAmount
    coinValue
    reelSetId
    result {
      reelPositions
      winCoinAmount
    }
    data
    createdAt
    updatedAt
    userBonus {
      betId
      bonusId
      bonus {
        coinAmount
      }
    }
  }
`);

export const betCoinRewardFragment = gql(`
  fragment betCoinReward on BetCoinReward {
    amount
  }
`);
export const betBonusRewardFragment = gql(`
  fragment betBonusReward on BetBonusReward {
    userBonusId
    userBonus {
      ...userBonus
    }
  }
`);
export const replayBonusRewardFragment = gql(`
  fragment replayBonusReward on ReplayBonusReward {
    userBonusId
    userBonus: replayUserBonus {
      ...userBonus
    }
  }
`);
