import { gql } from './__generated__';

export const configGql = gql(`
  query Config {
    isTurboSpin @client
    isSoundOn @client
    isSpinInProgress @client
    isSlotBusy @client
    isSlotStopped @client
    isOpenBetSettingsPopup @client
    isOpenAutoplayPopup @client
    isOpenInfoPopup @client
    isOpenHistoryPopup @client
  }
`);

export const getUserGql = gql(`
  query getUser {
    user {
      id
      clientId
      balance {
        amount
        currency
      }
    }
  }
`);

export const replayGetUserGql = gql(`
  query replayGetUser {
    user {
      id
      clientId
      balance {
        amount
        currency
      }
    }
  }
`);

export const getBetAmountGql = gql(`
  query GetBet {
    betAmount @client
  }
`);

export const getWinAmountGql = gql(`
  query GetWin {
    winAmount @client
  }
`);

export const getAutoSpinsSettingsGql = gql(`
  query GetAutoSpinsSettings {
    autoSpinsAmount @client
    stopOnWinExceeds @client
    stopOnBalanceIncrease @client
    stopOnBalanceDecrease @client
    isStopOnAnyWin @client
    isStopOnFeatureWin @client
    isStopOnWinExceeds @client
    isStopOnBalanceIncrease @client
    isStopOnBalanceDecrease @client
    autoSpinsStartBalance @client
  }
`);

export const getAutoSpinsGql = gql(`
  query GetAutoSpin {
    isAutoSpins @client
    autoSpinsLeft @client
  }
`);

export const slotHistoryGql = gql(`
  query slotHistory($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        count
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...historyNode
        }
      }
    }
  }
`);

export const replaySlotHistoryGql = gql(`
  query replaySlotHistory($input: GetBetsInput!) {
    bets(input: $input) {
      pageInfo {
        count
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...historyNode
        }
      }
    }
  }
`);

export const slotBetGql = gql(`
  query bet($input: GetBetInput!) {
    bet(input: $input) {
      id
      coinAmount
      coinValue
      slotId
      status
      reelSetId
      result {
        reelPositions
        winCoinAmount
      }
      userBonus {
        betId
      }
      data
    }
  }
`);
export const getSlotGql = gql(`
  query getSlot($input: GetSlotInput!) {
    slot(input: $input) {
      id
      reels {
        id
        type
        layout
      }
      lines
      icons {
        id
        combos {
          pattern
          rewards {
            type
            multiplier
          }
        }
      }
      settings {
        startPosition
      }
      clientSettings {
        coinAmounts {
          default
          quick
        }
        coinValues {
          code
          variants
        }
        features {
          id
          enabled
        }
        autoplay {
          options
          conditions {
            stopOnAnyWin {
              enabled
            }
            stopIfFeatureIsWon {
              enabled
            }
            stopIfSingleWinExceeds {
              enabled
              multipliers
            }
            stopIfBalanceDecreasesBy {
              enabled
              multipliers
            }
            stopIfBalanceIncreasesBy {
              enabled
              multipliers
            }
          }
        }
      }
      lineSets {
        id
        lines
        coinAmountMultiplier
      }
    }
  }
`);

export const getBonuses = gql(`
  query getBonuses($input: GetBonusesInput) {
    bonuses(input: $input) {
      id
      type
      coinAmount
    }
  }
`);

export const getUserBonuses = gql(`
  query getUserBonuses($input: GetUserBonusesInput) {
    userBonuses(input: $input) {
      ...userBonus
    }
  }
`);

export const getReplayUserBonuses = gql(`
  query getReplayUserBonuses($input: GetUserBonusesInput) {
    userBonuses(input: $input) {
      ...userBonus
    }
  }
`);

export const replayBetGql = gql(`
  query replayBet($betId: ID!) {
    placeBet: replayRound(betId: $betId) {
      bet {
        id
        coinAmount
        coinValue
        reelSet {
          id
          type
        }
        lineSet {
          id
          coinAmountMultiplier
        }
        result {
          reelPositions
          winCoinAmount
        }
        data
        userBonus: replayUserBonus {
          bonusId
        }
      }
      paylines {
        lineId
        winPositions
        amount
      }
      balance {
        placed {
          amount
          currency
        }
        settled {
          amount
          currency
        }
      }
      rewards {
        __typename
        ...betCoinReward
        ...replayBonusReward
      }
    }
  }
`);

export const betsByInitialRoundId = gql(`
  query betsByInitialRoundId($initialRoundId: UUID!) {
    betsByInitialRoundId(initialRoundId: $initialRoundId) {
      id
      result {
        reelPositions
        winCoinAmount
      }
      reelSetId
    }
  }
`);

export const isStoppedGql = gql(`
  query IsStopped {
    isSlotStopped @client
  }
`);
